const generalUtil = {
    onKeyDown (event) {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    },

    /**
     * Locks Scrolling on a page.
     */
    toggleScrollLock() {
        document.querySelector('html').classList.toggle('scroll-lock');
    }
}
export default generalUtil;