import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Table from "./Table"
import {useParams} from 'react-router-dom';
import dateHelpers from "../../utils/DateHelpers"
import restUtil from "../../utils/RestUtil";

const propTypes = {
    id: PropTypes.number,
    axios: PropTypes.func.isRequired,
};

const defaultSorted = [{
    dataField: 'update_id',
    order: 'desc'
}];

// This class contains the information to build a table for all of an incident's updates
function UpdatesTable(props) {
    // The error hook will be used for future error handling
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);
    const [entries, setEntries] = useState([]);
    const [teamsInfo, setTeamsInfo] = useState([]);
    const id = useParams()['id'];

    // Cleans the data returned from the database for usage in the table
    const getCleanData = () => {
        let cleanedEntries = structuredClone(entries);
        if (cleanedEntries !== null && cleanedEntries !== undefined) {
            for (let i = 0; i < cleanedEntries.length; i++) {
                let tableEntry = cleanedEntries.at(i);
                tableEntry['update_time'] = dateHelpers.reformatDate(new Date(tableEntry['update_time']));
                tableEntry['entry_id'] = i;
                tableEntry['jira_update_formatted'] = (
                    <a href={tableEntry['comment_url']} target="_blank" rel="noopener noreferrer">
                        {tableEntry['jira_key']}
                    </a>
                );
                if(!teamsInfo)
                {
                    tableEntry['teams_url'] = null;
                }
                else
                {
                    tableEntry['teams_url'] = (
                        <a href={teamsInfo[0]} target="_blank" rel="noopener noreferrer">Teams Link</a>
                    );
                }
                tableEntry['teams_meeting_code'] = (teamsInfo != null ? teamsInfo[1] : "")
            }
        }
        return cleanedEntries;
    }

    useEffect(() => {
        restUtil.getRequest(props.axios, `/sevbot/incidents/updates/${id}`, setEntries);
        restUtil.getRequest(props.axios, `/sevbot/MicrosoftTeams/getTeamsInfo/${id}`, setTeamsInfo);
    }, [id, props.axios]);

    const columns = [
        {
            dataField: 'update_id',
            text: 'Id',
            sort: true
        },
        {
            dataField: 'update_time',
            text: 'Update Time'
        },
        {
            dataField: 'status_update',
            text: 'Historical Status Updates'
        },
        {
            dataField: 'technical_update',
            text: 'Historical Technical Impacts'
        },
        {
            dataField: 'customer_update',
            text: 'Historical Customer Impacts'
        },
        {
            dataField: 'jira_update_formatted',
            text: 'Jira Update'
        },
        {
            dataField: 'teams_url',
            text: 'Teams URL'
        },
        {
            dataField: 'teams_meeting_code',
            text: 'Teams Meeting Code'
        },
    ];

    return (
        <Table
            data={getCleanData()}
            columns={columns}
            defaultSorted={defaultSorted}
            keyField={'update_id'}
        />
    );
}

UpdatesTable.propTypes = propTypes;

export default UpdatesTable;