import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import PropTypes from 'prop-types';
import restUtil from "../../utils/RestUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    selectedSystems: PropTypes.arrayOf(PropTypes.string),
    axios: PropTypes.func.isRequired,
    id: PropTypes.number,
    currentIncident: PropTypes.arrayOf(PropTypes.number)
};

const PageModal = (props) => {
        const [escalationPolicyIds, setEscalationPolicyIds] = useState([]);
        const pagerDutyLink = "https://garmin.pagerduty.com/incidents/";
        const [pagedIncident, setPagedIncident] = useState(null);
        const [postCallPagedIncidents, setPostCallPagedIncidents] = useState(null);

        const handleSubmit = async (event) => {
            event.preventDefault();

            let data = {
                id: props.currentIncident['incident_id'],
                system_names: escalationPolicyIds.join(',')
            };

            try {
                await restUtil.postRequestNoReload(props.axios, '/sevbot/pagerduty/createPagerDutyIncidents', data)
                    .then((responseData) => {
                        setPostCallPagedIncidents(responseData);
                    });
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };


        useEffect(() => {
            if (props.selectedSystems.length > 0) {
                setEscalationPolicyIds([props.selectedSystems[0]]);
            }
        }, [props.selectedSystems]);


        useEffect(() => {
            let data = {
                id: props.id
            };

            restUtil.getRequestWithData(props.axios, '/sevbot/pagerduty/getPagerDutyIds', data)
                .then((responseData) => {
                    setPagedIncident(responseData);
                })
                .catch((error) => {
                    console.log(error);
                })
        }, [props.id, props.axios, postCallPagedIncidents]);

        const handleClose = () => {
            setEscalationPolicyIds([]);
            props.onHide();
        };

        const handleSystemChange = (selectedOptions) => {
            const selectedIds = selectedOptions.map((option) => option.value);
            setEscalationPolicyIds(selectedIds);
        };

        const systemOptions = props.selectedSystems.map((system) => ({
            value: system,
            label: system,
        }));

        const tooltip = (
            <Tooltip id="tooltip">
                System must have Escalation Policy Id in order to be paged. Please contact the SevBot Admin if
                you would like to add an Escalation Policy Id to a System.
            </Tooltip>
        );

        return (
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Open a Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Select System(s) to be Paged</Form.Label>
                            <OverlayTrigger placement="left" overlay={tooltip}>
                                <FontAwesomeIcon icon={faCircleInfo} data-tip data-for='creatorTip' className="icon-button" />
                            </OverlayTrigger>
                            <Select
                                isMulti
                                onChange={handleSystemChange}
                                options={systemOptions}
                                value={systemOptions.filter((option) => escalationPolicyIds.includes(option.value))}
                            />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                    </Form>
                    {pagedIncident !== null && Object.keys(pagedIncident).length > 0 ? (
                        <div>
                            <h3 style={{ fontSize: '15px' }}>Open Pages</h3>
                            {pagedIncident.map((object) =>
                                object['pager_duty_incident_id'] ? (
                                    <div key={object['pager_duty_incident_id']}>
                                        <a
                                            href={pagerDutyLink + object['pager_duty_incident_id']}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {object['name']}
                                        </a>
                                        <br></br>
                                    </div>
                                ) : null
                            )}
                        </div>
                    ) : null}
                </Modal.Body>
            </Modal>
        );
    }
;

PageModal.propTypes = propTypes;

export default PageModal;
