import React, {Component} from 'react';
import PropTypes from "prop-types";
import Operations from "../../enums/Operations";

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    operation: PropTypes.symbol,
    id: PropTypes.number,
    axios: PropTypes.func.isRequired
}

const defaultProps = {
    operation: null,
    id: -1,
    axios: null
};

// Form for Contact Group Creation and Updating
export class TeamCreateUpdateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                'name': '',
                'distribution_email': '',
                'default_team': null
            }
        };

        TeamCreateUpdateForm.propTypes = propTypes;
        TeamCreateUpdateForm.defaultProps = defaultProps;
    }

    componentDidMount() {
        if (this.props.operation === Operations.Update) {
            (this.props.axios).get(
                '/sevbot/teams/getTeam',
                {
                    params: {
                        id: this.props.id
                    }
                }
            )
                .then((result) => {
                    this.setState({
                        data: result.data[0]
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <form onSubmit={this.props.onSubmit}>
                <input type="hidden" id="id" value={this.props.id}/>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        className="form-control"
                        id="name"
                        defaultValue={this.props.operation === Operations.Update ? this.state.data['name']
                            : null}
                        placeholder="firstname lastname"
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                        className="form-control"
                        id="email"
                        defaultValue={this.props.operation === Operations.Update ? this.state.data['distribution_email']
                            : null}
                        placeholder="name@example.com"
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <input
                        type="checkbox"
                        id="defaultTeam"
                        defaultChecked={this.props.operation === Operations.Update ? this.state.data['default_team']
                            : null}
                    />
                    <label htmlFor="defaultTeam">
                        Default Team
                    </label>
                </div>
                <div className="form-group">
                    <button className="form-control btn btn-primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        );
    }
}

export default TeamCreateUpdateForm;
