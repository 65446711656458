import React from 'react';

// This builds the title bar for the webpage
export const Titlebar = () => {
    return (
        <nav className="navbar bg-light">
            <div className="container-fluid">
                <h1 className="navbar-brand">Garmin Device Interaction Sev Management</h1>
            </div>
        </nav>
    );
}
export default Titlebar;
