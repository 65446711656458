import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Table from "./Table";
import SortBehavior from "../../enums/SortBehavior";
import dateHelpers from "../../utils/DateHelpers";

const propTypes = {
    id: PropTypes.number,
    axios: PropTypes.func.isRequired,
};

const columns = [
    {
        dataField: 'incident_id',
        text: 'Incident Id',
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'level',
        text: 'Level'
    },
    {
        dataField: 'start_time',
        text: 'Start Time'
    }, {
        dataField: 'end_time',
        text: 'End Time'
    }, {
        dataField: 'duration',
        text: 'Duration'
    }, {
        dataField: 'owner_name',
        text: 'Owner Name'
    }, {
        dataField: 'optional_channel_name',
        text: 'Channel Name'
    }, {
        dataField: 'incident_systems_as_string',
        text: 'Incident Systems'
    }, {
        dataField: 'data_centers_as_string',
        text: 'Data Centers'
    }, {
        dataField: 'last_updated',
        text: 'Last Update Time'
    }, {
        dataField: 'latest_technical_update',
        text: 'Latest Technical Impact'
    }, {
        dataField: 'latest_customer_update',
        text: 'Latest Customer Impact'
    }, {
        dataField: 'latest_status_update',
        text: 'Latest Status Update'
    }, {
        dataField: 'all_updates',
        text: 'All Updates'
    }, {
        dataField: 'transfer_owner',
        text: 'Transferred To'
    }, {
        dataField: 'pager_duty_link',
        text: 'Pager Duty Link'
    }
];

const defaultSorted = [{
    dataField: 'incident_id',
    order: 'desc'
}];

const ClosedTable = props => {
    const [entries, setEntries] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortBehavior, setSortBehavior] = useState(SortBehavior.SortByIdDesc);

    useEffect(() => {
        fetchData();
        fetchNumRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum, sizePerPage, searchTerm, sortBehavior]);

    const handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
        if (type === 'pagination') {
            setPageNum(page);
            setSizePerPage(sizePerPage);
        }
        else if (type === 'filter') {
            const searchTerm = filters.search;
            //Reset the page number since the number of pages may have changed
            setPageNum(1);
            setSearchTerm(searchTerm);
        }
        else if (type === 'sort') {
            let sortBehavior;
            if (sortField === 'incident_id') {
                sortBehavior = sortOrder === 'asc' ? SortBehavior.SortByIdAsc : SortBehavior.SortByIdDesc;
            }
            else if (sortField === 'name') {
                sortBehavior = sortOrder === 'asc' ? SortBehavior.SortByNameAsc : SortBehavior.SortByNameDesc;
            }
            setSortBehavior(sortBehavior);
        }
    };

    const fetchData = () => {
        let url = "/sevbot/incidents/closed?"
            + `pageNumber=${pageNum}&`
            + `pageSize=${sizePerPage}&`
            + `searchTerm=${searchTerm}&`
            + `sortBehavior=${SortBehavior.toString(sortBehavior)}`;

        props.axios.get(url)
            .then((result) => {
                setEntries(result.data.incidents);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchNumRows = () => {
        let url = `/sevbot/incidents/closedCount?searchTerm=${searchTerm}`;

        props.axios.get(url)
            .then((result) => {
                setTotalSize(result.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Cleans the data returned from the database for usage in the table
    const getCleanData = () => {
        let cleanedEntries = structuredClone(entries);

        if (cleanedEntries !== null && cleanedEntries !== undefined) {
            for (let i = 0; i < cleanedEntries.length; i++) {
                let closedIncident = cleanedEntries.at(i);
                let durationMinutes = (new Date(closedIncident['end_time']) - new Date(closedIncident['start_time'])) / (1000 * 60);
                closedIncident['duration'] = dateHelpers.getFormattedDuration(durationMinutes)
                closedIncident['start_time'] = dateHelpers.reformatDate(new Date(closedIncident['start_time']));
                closedIncident['end_time'] = dateHelpers.reformatDate(new Date(closedIncident['end_time']));
                closedIncident['last_updated'] = dateHelpers.reformatDate(new Date(closedIncident['last_updated']));
                closedIncident['entry_id'] = closedIncident['incident_id'];
                closedIncident['all_updates'] =
                    <td key={`all_updates${closedIncident['incident_id']}`}>
                        <a href={`updates/${closedIncident['incident_id']}`}>See All Updates</a>
                    </td>
            }
        }
        return cleanedEntries
    };

    return (
        <Table
            data={getCleanData()}
            columns={columns}
            page={pageNum}
            sizePerPage={sizePerPage}
            totalSize={totalSize}
            onTableChange={handleTableChange}
            defaultSorted={defaultSorted}
            keyField={'incident_id'}
            remoteFiltering={true}
            remotePagination={true}
            remoteSorting={true}
        />
    );
};

ClosedTable.propTypes = propTypes;

export default ClosedTable;
