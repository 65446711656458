import React from 'react';
import PropTypes from "prop-types";

export const LogInPage = ({onLogIn, invalidCredentials}) => {
    return (
        <React.Fragment>
            <form className="login-form" onSubmit={onLogIn}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        className="form-control"
                        id="username"
                        placeholder="myusername1"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        className="form-control"
                        type="password"
                        id="password"
                        placeholder="password123"
                    />
                </div>
                <div className="form-group">
                    <button className="form-control btn btn-primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
            {invalidCredentials && <p className="invalid-warning">Invalid Login Credentials</p>}
        </React.Fragment>
    );
};

LogInPage.propTypes = {
    onLogIn: PropTypes.func.isRequired,
    invalidCredentials: PropTypes.bool
}

LogInPage.defaultProps = {
    invalidCredentials: false
};

export default LogInPage;
