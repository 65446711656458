import React from 'react';
import {
    Nav,
    NavLink,
    NavMenu,
    NavBtn,
    NavBtnLink
} from './NavbarStyle';
import PropTypes from "prop-types";

// This builds the navigation bar for the webpage
function Navbar( { isLoggedIn, onLogOut } ){
    return (
        <Nav>
            <NavMenu>
                <NavLink to='/openIncidents'>
                    Open Incidents
                </NavLink>
                <NavLink to='/closedIncidents'>
                    Closed Incidents
                </NavLink>
                <NavLink to='/teams'>
                    Teams
                </NavLink>
                <NavLink to='/systems'>
                    Systems
                </NavLink>
            </NavMenu>
            {isLoggedIn &&
                <NavBtn onClick={onLogOut}>
                    <NavBtnLink to='/'>Log Out</NavBtnLink>
                </NavBtn>
            }
        </Nav>
    );
}

Navbar.propTypes = {
    isLoggedIn: PropTypes.bool,
    onLogOut: PropTypes.func.isRequired
}

Navbar.defaultProps = {
    isLoggedIn: false
};

export default Navbar;
