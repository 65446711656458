import React, {Component} from 'react';
import PropTypes from "prop-types";
import OpenTable from "../components/Table/OpenTable";
import TriggerButton from "../components/ModalForm/TriggerButton";
import generalUtil from "../utils/GeneralUtil";
import Modal from "../components/ModalForm/Modal";
import TableTypes from "../enums/TableTypes";
import Operations from "../enums/Operations";
import restUtil from "../utils/RestUtil";

const propTypes = {
    axios: PropTypes.func.isRequired
};

const incidentsTriggerText = 'Create New Incident (Sev)';

export class OpenIncidentsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsShown: false,
            level: "",
            systems: [],
            teams: [],
            dataCenters: [],
            data: []
        };
        this.handleTeamChange = this.handleTeamChange.bind(this)
        this.handleLevelChange = this.handleLevelChange.bind(this);
        this.handleSystemChange = this.handleSystemChange.bind(this);
        this.handleDataCenterChange = this.handleDataCenterChange.bind(this);
    }

    showModal = () => {
        this.setState({modalIsShown: true});
        generalUtil.toggleScrollLock();
    };

    closeModal = () => {
        this.setState({
            modalIsShown: false,
            level: "",
            teams: [],
            systems: [],
            dataCenters: []
        });
        if (this.TriggerButton !== undefined) {
            this.TriggerButton.focus();
        }
        generalUtil.toggleScrollLock();
    };

    handleLevelChange(level) {
        this.setState({
            level: level.toLowerCase()
        })
    }

    // Handles change of user input in the teams field when creating a new team
    handleTeamChange(teams) {
        this.setState({
            teams: teams
        });
    }

    // Handles change of user input in the systems field when creating a new sev
    handleSystemChange(systems) {
        this.setState({
            systems: systems
        });
    }

    // Handles change of user input in the data center field when creating a new sev
    handleDataCenterChange(dataCenters) {

        this.setState({
            dataCenters: dataCenters
        });
    }

    // Handles submission of incident creation form
    incidentCreateOnSubmit = (event) => {
        event.preventDefault(event);

        let teams = [];
        let systems = [];
        let dataCenters = [];

        (this.state.teams).forEach((team) => {
            teams.push(team.value);
        });

        (this.state.systems).forEach((system) => {
            systems.push(system.value);
        });

        (this.state.dataCenters).forEach((dataCenter) => {
            dataCenters.push(dataCenter.value);
        });

        this.setState({
            data: {
                title: event.target.title.value,
                level: this.state.level,
                start_time: (event.target.start_time.value).toString(),
                slack_channel: event.target.slack_channel.value,
                creator: event.target.creator.value,
                owner: event.target.owner.value,
                teams: teams.toString(),
                systems: systems.toString(),
                data_centers: dataCenters.toString(),
                technical_update: event.target.technical_impact.value,
                customer_update: event.target.customer_impact.value,
                status_update: event.target.status_update.value,
                adhoc_emails: event.target.communication_emails.value,
                creator_jira_username: event.target.creator_jira_username.value,
                owner_jira_username: event.target.owner_jira_username.value
            }
        }, () => {
            restUtil.postRequest(this.props.axios, '/sevbot/incidents/create', this.state.data);
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modalIsShown ? (<Modal
                    onSubmit={this.incidentCreateOnSubmit}
                    closeModal={this.closeModal}
                    onKeyDown={generalUtil.onKeyDown}
                    tableType={TableTypes.Open}
                    operation={Operations.Create}
                    axios={this.props.axios}
                    onLevelChange={this.handleLevelChange}
                    onTeamChange={this.handleTeamChange}
                    onSystemChange={this.handleSystemChange}
                    onDataCenterChange={this.handleDataCenterChange}
                    selectedLevel={this.state.level}
                    selectedTeams={this.state.teams}
                    selectedSystems={this.state.systems}
                    selectedDataCenters={this.state.dataCenters}
                />) : null}
                <div className='tableContainer'>
                    <TriggerButton
                        showModal={this.showModal}
                        buttonRef={(n) => (this.TriggerButton = n)}
                        triggerText={incidentsTriggerText}
                    />
                    <OpenTable axios={this.props.axios}/>
                </div>
            </React.Fragment>
        );
    }
}

OpenIncidentsPage.propTypes = propTypes;
