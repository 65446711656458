import React, {Component} from 'react';
import TriggerButton from "../components/ModalForm/TriggerButton";
import TeamsTable from "../components/Table/TeamsTable";
import PropTypes from "prop-types";
import Operations from "../enums/Operations";
import TableTypes from "../enums/TableTypes";
import Modal from "../components/ModalForm/Modal";
import generalUtil from "../utils/GeneralUtil";
import restUtil from "../utils/RestUtil";

const propTypes = {
    axios: PropTypes.func.isRequired
};

const teamsTriggerText = 'Create Team';

export class TeamsPage extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            modalIsShown: false,
            teams: []
        }
        this.handleTeamChange = this.handleTeamChange.bind(this);
    }

    showModal = () => {
        this.setState({modalIsShown: true});
        generalUtil.toggleScrollLock();
    };

    closeModal = () => {
        this.setState({
            modalIsShown: false,
            teams: [],
        });
        if (this.TriggerButton !== undefined) {
            this.TriggerButton.focus();
        }
        generalUtil.toggleScrollLock();
    };

    // Handles change of user input in the teams field when creating a new team
    handleTeamChange(teams) {
        this.setState({
            teams: teams
        });
    }

    // Submit function for Team create button
    teamsOnSubmit = (event) => {
        event.preventDefault(event);
        this.closeModal();
        this.setState({
            data: {
                name: event.target.name.value,
                email: event.target.email.value,
                defaultTeam: event.target.defaultTeam.checked
            }
        }, () => {
            restUtil.postRequest(this.props.axios, '/sevbot/teams/createTeam', this.state.data);
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.modalIsShown ? (<Modal
                    onSubmit={this.teamsOnSubmit}
                    closeModal={this.closeModal}
                    onKeyDown={generalUtil.onKeyDown}
                    tableType={TableTypes.Teams}
                    operation={Operations.Create}
                    axios={this.props.axios}
                    onTeamChange={this.handleTeamChange}
                    selectedTeams={this.state.teams}
                />) : null}
            <div className='tableContainer'>
                <TriggerButton
                    showModal={this.showModal}
                    buttonRef={(n) => (this.TriggerButton = n)}
                    triggerText={teamsTriggerText}
                />
                <TeamsTable axios={this.props.axios}/>
            </div>
            </React.Fragment>
        )
    }
}
TeamsPage.propTypes = propTypes;
export default TeamsPage;