import React, {Component} from 'react';
import './App.css';
import Titlebar from './components/Titlebar';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import UpdatesTable from "./components/Table/UpdatesTable"
import LogInPage from "./components/logInPage";
import axios from "axios";
import TeamsPage from "./pages/TeamsPage";
import SystemsPage from "./pages/SystemsPage";
import generalUtil from "./utils/GeneralUtil";
import {OpenIncidentsPage} from "./pages/OpenIncidentsPage";
import {ClosedIncidentsPage} from "./pages/ClosedIncidentsPage";

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: sessionStorage.getItem("isLoggedIn") === "true",
            token: (sessionStorage.getItem("token") === null) ? null : sessionStorage.getItem("token"),
            expirationDate: (sessionStorage.getItem("expirationDate") === null) ? null : new Date(sessionStorage.getItem("expirationDate")),
            invalidCredentials: false,
            securityData: [],
            axios: axios.create({
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            })
        };

    }

    componentDidMount() {
        this.setTimer();
    }

    // Sets state with credentials input by user
    onLogIn = (event) => {
        event.preventDefault();

        this.setState({
            securityData: {
                username: event.target.username.value,
                password: event.target.password.value
            }
        }, () => {

            this.validateCredentials();
        });
    }

    // Retrieves token and logs user in by updating state and session with appropriate token, token expiration time, and logged-in boolean
    validateCredentials() {

        // Retrieve token
        axios({
            method: 'post',
            url: '/sevbot/auth/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: this.state.securityData
        })
            .then((result) => {
                sessionStorage.setItem("isLoggedIn", "true");

                // Add the time until expiration to the current time to get an expiration time
                let expiration = new Date();
                expiration.setSeconds(expiration.getSeconds() + result.data.expires_in);
                sessionStorage.setItem("expirationDate", expiration.toString());

                sessionStorage.setItem("token", result.data.access_token);

                const instance = axios.create({
                    headers: {'Authorization': `Bearer ${result.data.access_token}`}
                })

                this.setState({
                        isLoggedIn: true,
                        token: result.data.access_token,
                        expirationDate: expiration,
                        invalidCredentials: false,
                        axios: instance
                    },
                    () => {
                        this.setTimer()
                    }
                );
            })
            .catch((error) => {
                // Set state to cue invalid message on re-render
                this.setState({
                    invalidCredentials: true
                });
                console.log(error);
            });
    }

    // Clears the token and logs out the user both in the state and the session
    onLogOut = () => {
        this.setState({
            isLoggedIn: false,
            token: null,
            expirationDate: null,
            axios: null
        })
        sessionStorage.setItem("isLoggedIn", "false");
        sessionStorage.setItem("token", null);
        sessionStorage.setItem("expirationDate", null);
    }

    /* This method finds the time until the token's expiration and sets a timer.
     * The session stores the expiration time instead of the amount of time until expiration, because the time of token expiration remains constant.
     * The timer is recreated on every re-render, meaning we need to know how much time is left on every re-render.
     * If the time left is stored, the timer would restart from that time on every re-render.
     * Storing the expiration time allows us to start the timer at the correct place by calculating how long we have until the expiration.
     */
    setTimer() {
        let timeLeft = this.state.expirationDate - new Date();
        const timer = setTimeout(() => {
            this.onLogOut();
        }, timeLeft);
        return () => clearTimeout(timer);
    }

    showModal = () => {
        this.setState({isShown: true});
        generalUtil.toggleScrollLock();
    };

    render() {
        return (
            <div className="App">
                <Titlebar/>
                <Router>
                    <Navbar isLoggedIn={this.state.isLoggedIn} onLogOut={this.onLogOut}/>

                    {!this.state.isLoggedIn &&
                    <LogInPage onLogIn={this.onLogIn} invalidCredentials={this.state.invalidCredentials}/>
                    }

                    {this.state.isLoggedIn &&
                    <Routes>
                        <Route exact path='/' element={<Navigate to="/openIncidents"/>}/>
                        <Route exact name='openIncidents' path='/openIncidents'element={<OpenIncidentsPage axios={this.state.axios}/>}/>
                        <Route exact path='/closedIncidents' element={<ClosedIncidentsPage axios={this.state.axios}/>}/>
                        <Route exact path='/updates/:id' element={<UpdatesTable axios={this.state.axios}/>}/>
                        <Route exact path='/teams' element={<TeamsPage axios={this.state.axios}/>}/>
                        <Route exact path='/systems' element={<SystemsPage axios={this.state.axios}/>}/>
                    </Routes>
                    }
                </Router>
            </div>
        );
    }
}

export default App;
