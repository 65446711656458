import React from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import TeamCreateUpdateForm from "./TeamCreateUpdateForm";
import PropTypes from "prop-types";
import ConfirmDeletionForm from "./ConfirmDeletionForm";
import IncidentCreateUpdateForm from "./IncidentCreateUpdateForm";
import TableTypes from "../../enums/TableTypes";
import Operations from "../../enums/Operations";
import SystemCreateUpdateForm from "./SystemCreateUpdateForm";

// Modal for CRUD functions
export const Modal = ({
                          onKeyDown,
                          closeModal,
                          onSubmit,
                          tableType,
                          operation,
                          id,
                          axios,
                          onLevelChange,
                          selectedLevel,
                          onTeamChange,
                          selectedTeams,
                          onSystemChange,
                          selectedSystems,
                          onDataCenterChange,
                          selectedDataCenters
                      }) => {
    return ReactDOM.createPortal(
        <FocusTrap>
            <aside
                role="dialog"
                tabIndex="-1"
                aria-modal="true"
                className="modal-cover"
                onKeyDown={onKeyDown}
            >
                <div className="modal-area">
                    <button
                        aria-label="Close Modal"
                        aria-labelledby="close-modal"
                        className="_modal-close"
                        onClick={closeModal}
                    >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
                        <svg className="_modal-close-icon" viewBox="0 0 40 40">
                            <path d="M 10,10 L 30,30 M 30,10 L 10,30"/>
                        </svg>
                    </button>
                    <div className="modal-body">
                        {tableType === TableTypes.Teams &&
                            (operation === Operations.Create || operation === Operations.Update) &&
                            <TeamCreateUpdateForm
                                onSubmit={onSubmit}
                                operation={operation}
                                axios={axios}
                                id={id}
                            />
                        }
                        {tableType === TableTypes.Systems &&
                            (operation === Operations.Create || operation === Operations.Update) &&
                            <SystemCreateUpdateForm
                                onSubmit={onSubmit}
                                operation={operation}
                                axios={axios}
                                id={id}
                            />
                        }
                        {(tableType === TableTypes.Open || tableType === TableTypes.Closed) &&
                            (operation === Operations.Create || operation === Operations.Update) &&
                            <IncidentCreateUpdateForm
                                onSubmit={onSubmit}
                                operation={operation}
                                axios={axios}
                                id={id}
                                onLevelChange={onLevelChange}
                                selectedLevel={selectedLevel}
                                onTeamChange={onTeamChange}
                                selectedTeams={selectedTeams}
                                onSystemChange={onSystemChange}
                                selectedSystems={selectedSystems}
                                onDataCenterChange={onDataCenterChange}
                                selectedDataCenters={selectedDataCenters}
                            />
                        }
                        {(tableType === TableTypes.Teams && operation === Operations.Delete) ||
                        (tableType === TableTypes.Systems && operation === Operations.Delete) ? (
                            <ConfirmDeletionForm
                                id={id}
                                onSubmit={onSubmit}
                                closeModal={closeModal}
                            />) : null
                        }
                    </div>
                </div>
            </aside>
        </FocusTrap>,
        document.body
    );
};

Modal.propTypes = {
    onKeyDown: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    tableType: PropTypes.symbol,
    operation: PropTypes.symbol,
    id: PropTypes.number,
    axios: PropTypes.func.isRequired,
    onLevelChange: PropTypes.func,
    selectedLevel: PropTypes.string,
    onTeamChange: PropTypes.func,
    selectedTeams: PropTypes.array,
    onSystemChange: PropTypes.func,
    selectedSystems: PropTypes.array,
    onDataCenterChange: PropTypes.func,
    selectedDataCenters: PropTypes.array
}

Modal.defaultProps = {
    tableType: null,
    operation: null,
    id: -1,
    axios: null,
    selectedLevels: "",
    selectedTeams: [],
    selectedSystems: [],
    selectedDataCenters: []
};

export default Modal;
