import React, {Component} from 'react';
import TriggerButton from "../components/ModalForm/TriggerButton";
import PropTypes from "prop-types";
import Operations from "../enums/Operations";
import TableTypes from "../enums/TableTypes";
import Modal from "../components/ModalForm/Modal";
import generalUtil from "../utils/GeneralUtil";
import restUtil from "../utils/RestUtil";
import SystemsTable from "../components/Table/SystemsTable";

const propTypes = {
    axios: PropTypes.func.isRequired
};

const systemsTriggerText = 'Create System';

export class SystemsPage extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            modalIsShown: false,
            systems: []
        }
        this.handleSystemChange = this.handleSystemChange.bind(this);
    }

    showModal = () => {
        this.setState({modalIsShown: true});
        generalUtil.toggleScrollLock();
    };

    closeModal = () => {
        this.setState({
            modalIsShown: false,
            systems: [],
        });
        if (this.TriggerButton !== undefined) {
            this.TriggerButton.focus();
        }
        generalUtil.toggleScrollLock();
    };

    // Handles change of user input in the systems field when creating a new system
    handleSystemChange(systems) {
        this.setState({
            systems: systems
        });
    }

    // Submit function for system create button
    systemsOnSubmit = (event) => {
        event.preventDefault(event);
        this.closeModal();
        this.setState({
            data: {
                name: event.target.name.value,
                system_names: event.target.escalationPolicy.value
            }
        }, () => {
            restUtil.postRequest(this.props.axios, '/sevbot/systems/createSystem', this.state.data);
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.modalIsShown ? (<Modal
                    onSubmit={this.systemsOnSubmit}
                    closeModal={this.closeModal}
                    onKeyDown={generalUtil.onKeyDown}
                    tableType={TableTypes.Systems}
                    operation={Operations.Create}
                    axios={this.props.axios}
                    onSystemChange={this.handleSystemChange}
                    selectedSystems={this.state.systems}
                />) : null}
                <div className='tableContainer'>
                    <TriggerButton
                        showModal={this.showModal}
                        buttonRef={(n) => (this.TriggerButton = n)}
                        triggerText={systemsTriggerText}
                    />
                    <SystemsTable axios={this.props.axios}/>
                </div>
            </React.Fragment>
        )
    }
}
SystemsPage.propTypes = propTypes;
export default SystemsPage;