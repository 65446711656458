const restUtil = {
    getRequest(axios, url, setStateHook) {
        axios.get(
            url
        )
            .then((response) => {
                setStateHook(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getRequestWithData(axios, url, data = {}) {
        return axios.get(
            url,
            { params: data }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    postRequest(axios, url, data) {
        axios.post(
            url,
            null,
            {params: data}
        )
            .then(function () {
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    postRequestNoReload(axios, url, data) {
        return axios.post(
            url,
            null,
            {params: data}
        )
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    putRequest(axios, url, data) {
        axios.put(
            url,
            null,
            {params: data}
        )
            .then(() => {
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    deleteRequest(axios, url, data) {
        axios.delete(
            url,
            {params: data}
        )
            .then(function () {
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    putRequestNoReload(axios, url, data) {
        return axios.put(url,
            null,
            {params: data}
        ).catch(function (error) {
            console.log(error);
        });
    }
}
export default restUtil;