import React, {Component} from 'react';
import PropTypes from "prop-types";
import ClosedTable from "../components/Table/ClosedTable";

const propTypes = {
    axios: PropTypes.func.isRequired
};

export class ClosedIncidentsPage extends Component {

    render() {
        return (
            <div className='tableContainer'>
                <ClosedTable axios={this.props.axios}/>
            </div>
        );
    }
}

ClosedIncidentsPage.propTypes = propTypes;
