import React from 'react';
import PropTypes from "prop-types";

export const ConfirmDeletionForm = ({ id, onSubmit, closeModal }) => {
    return (
        <form onSubmit={onSubmit}>
            <input type="hidden" id="id" value={id}/>
            <p className = "confirmation-question">Are you sure you would like to delete?</p>
                <button className="form-control btn btn-danger confirmation-button" type="submit">
                    Yes
                </button>
                <button className="form-control btn btn-secondary confirmation-button" type="button" onClick={closeModal}>
                    No
                </button>
        </form>
    );
};

ConfirmDeletionForm.propTypes = {
    id: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default ConfirmDeletionForm;