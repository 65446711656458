import React, {useState, useEffect} from 'react';
import CloseModal from "./CloseModal"
import Operations from "../../enums/Operations"
import PropTypes from 'prop-types';
import Table from "./Table"
import {BsFillArchiveFill} from "react-icons/bs";
import TableTypes from "../../enums/TableTypes";
import {faEdit, faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dateHelpers from "../../utils/DateHelpers"
import generalUtil from "../../utils/GeneralUtil";
import Modal from "../ModalForm/Modal";
import restUtil from "../../utils/RestUtil";
import PageModal from "./PageModal";

const propTypes = {
    id: PropTypes.number,
    axios: PropTypes.func.isRequired
};

const columns = [
    {
        dataField: 'incident_id',
        text: 'Incident Id',
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'level',
        text: 'Level'
    },
    {
        dataField: 'start_time',
        text: 'Start Time'
    }, {
        dataField: 'duration',
        text: 'Duration'
    }, {
        dataField: 'owner_name',
        text: 'Owner Name'
    }, {
        dataField: 'optional_channel_name',
        text: 'Channel Name'
    }, {
        dataField: 'incident_systems_as_string',
        text: 'Incident Systems'
    }, {
        dataField: 'data_centers_as_string',
        text: 'Data Centers'
    }, {
        dataField: 'last_updated',
        text: 'Last Update Time'
    }, {
        dataField: 'latest_technical_update',
        text: 'Latest Technical Impact'
    }, {
        dataField: 'latest_customer_update',
        text: 'Latest Customer Impact'
    }, {
        dataField: 'latest_status_update',
        text: 'Latest Status Update'
    }, {
        dataField: 'all_updates',
        text: 'All Updates'
    }, {
        dataField: 'edit',
        text: 'Update'
    }
];

const defaultSorted = [{
    dataField: 'incident_id',
    order: 'desc'
}];

// This component contains the information to build a table from json data
const OpenTable = props => {
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [currentIncidentId, setCurrentIncidentId] = useState(0);
    const [currentIncidentData, setCurrentIncidentData] = useState(0);
    const [cleanData, setCleanData] = useState([]);
    const [showPageModal, setShowPageModal] = useState(false);

    const [selectedLevel, setSelectedLevel] = useState("");
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectedDataCenters, setSelectedDataCenters] = useState([]);
    const [savedUserSystems, setSavedUserSystems] = useState([]);

    const [updateIncidentModalIsShown, setUpdateIncidentModalIsShown] = useState(false);
    const [selectedIncidentId, setSelectedIncidentId] = useState(null);

    // Cleans the data returned from the database for usage in the table
    const getCleanData = (entries) => {
        let cleanedEntries = structuredClone(entries);
        if (cleanedEntries !== null && cleanedEntries !== undefined) {
            for (let i = 0; i < cleanedEntries.length; i++) {
                let openIncident = cleanedEntries.at(i);
                let durationMinutes = Math.trunc((Date.now() - new Date(openIncident['start_time'])) / (1000 * 60));
                openIncident['duration'] = dateHelpers.getFormattedDuration(durationMinutes);
                openIncident['iso_start'] = structuredClone(openIncident['start_time']);
                openIncident['start_time'] = dateHelpers.reformatDate(new Date(openIncident['start_time']));
                openIncident['end_time'] = dateHelpers.reformatDate(new Date(openIncident['end_time']));
                openIncident['last_updated'] = dateHelpers.reformatDate(new Date(openIncident['last_updated']));
                openIncident['entry_id'] = openIncident['incident_id'];
                openIncident['all_updates'] = <td key={`all_updates${openIncident['incident_id']}`}>
                    <a href={`updates/${openIncident['incident_id']}`}>See All
                        Updates</a>
                </td>;
                openIncident['edit'] =
                    <td key={`edit${openIncident['incident_id']}`}>
                        <FontAwesomeIcon icon={faEdit} className={"icon-button"}
                                         onClick={() => showUpdateIncidentModal(openIncident['entry_id'])}/>
                        <BsFillArchiveFill onClick={() => {
                            setShowCloseModal(true);
                            setCurrentIncidentId(openIncident['incident_id']);
                            setCurrentIncidentData(openIncident);
                        }}
                                           type="button" data-toggle="tooltip" data-placement="bottom"
                                           title="close this incident"
                        />
                        <FontAwesomeIcon
                            icon={faMobileAlt}
                            className="icon-button"
                            onClick={() => {
                                setShowPageModal(true);
                                setCurrentIncidentData(openIncident);
                                setCurrentIncidentId(openIncident['incident_id']);
                                const userSystems = openIncident['incident_systems'].filter(system => system.escalation_policy_id && system.escalation_policy_id !== "").map(system => system.name);
                                setSavedUserSystems(userSystems);
                            }}
                            title="Page Incident"
                            style={{cursor: 'pointer'}}
                        />
                    </td>;
            }
        }
        return cleanedEntries;
    }

    const handleLevelChange = (level) => {
        setSelectedLevel(level.toLowerCase());
    }

    // Handles change of user input in the teams field when creating a new team
    const handleTeamChange = (teams) => {
        setSelectedTeams(teams);
    }

    // Handles change of user input in the systems field when creating a new sev
    const handleSystemChange = (systems) => {
        setSelectedSystems(systems);
    }

    // Handles change of user input in the data center field when creating a new sev
    const handleDataCenterChange = (dataCenters) => {
        setSelectedDataCenters(dataCenters);
    }

    const showUpdateIncidentModal = (tableRowId) => {
        setUpdateIncidentModalIsShown(true);
        setSelectedIncidentId(tableRowId);
        generalUtil.toggleScrollLock();
    };

    // Handles submission of form for updating incident
    const updateIncidentOnSubmit = (event, id, level) => {
        event.preventDefault(event);

        let data = {
            title: event.target.title.value,
            id: id,
            email_changes: event.target.email_changes.checked,
            nag_enabled: event.target.nagging.checked,
            level: level,
            start_time: (event.target.start_time.value).toString(),
            customer_impact_end_time: (event.target.customer_impact_end_time.value).toString(),
            slack_channel: event.target.slack_channel.value,
            updater: event.target.creator.value,
            owner: event.target.owner.value,
            teams: selectedTeams.map(function (team) {
                return team['value'];
            }).toString(),
            systems: selectedSystems.map(function (system) {
                return system['value'];
            }).toString(),
            data_centers: selectedDataCenters.map(function (dataCenter) {
                return dataCenter['value'];
            }).toString(),
            technical_update: event.target.technical_impact.value,
            customer_update: event.target.customer_impact.value,
            status_update: event.target.status_update.value,
            adhoc_emails: event.target.communication_emails.value,
            creator_jira_username: event.target.creator_jira_username.value,
            owner_jira_username: event.target.owner_jira_username.value
        }
        restUtil.putRequest(props.axios, '/sevbot/incidents/update', data);
    }

    const closeUpdateIncidentModal = () => {
        setUpdateIncidentModalIsShown(false);
        generalUtil.toggleScrollLock();
    };

    const closeIncidentSubmit = (closeDate, closerId, closedJira) => {
        setShowCloseModal(false);
        let data = {
            id: currentIncidentData['incident_id'],
            closer: closerId,
            technical_update: currentIncidentData['latest_technical_update'],
            customer_update: currentIncidentData['latest_customer_update'],
            status_update: currentIncidentData['latest_status_update'],
            end_time: closeDate.toString(),
            close_jira_checkbox: closedJira
        }
        restUtil.putRequest(props.axios, '/sevbot/incidents/close', data);
    }

    const getOpenIncidents = () => {
        let url = '/sevbot/incidents/open';

        props.axios.get(url).then((result) => {
            setCleanData(() => getCleanData(result.data.incidents));
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
            console.log(error);
        })
    }

    useEffect(() => {
        getOpenIncidents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <CloseModal
                show={showCloseModal}
                onHide={() => setShowCloseModal(false)}
                id={currentIncidentId}
                submitClick={closeIncidentSubmit}
            />
            <Table
                data={cleanData}
                columns={columns}
                axios={props.axios}
                defaultSorted={defaultSorted}
                tableType={props.tableType}
                showButton={true}
                keyField={'incident_id'}
                remoteFiltering={false}
                remotePagination={false}
                remoteSorting={false}
            />
            <PageModal
                show={showPageModal}
                onHide={() => setShowPageModal(false)}
                id={currentIncidentId}
                axios={props.axios}
                selectedSystems={savedUserSystems}
                currentIncident={currentIncidentData}
            />

            {/* The Update Modal for updating a team */}
            {updateIncidentModalIsShown ?
                (<Modal
                    onSubmit={updateIncidentOnSubmit}
                    closeModal={closeUpdateIncidentModal}
                    onKeyDown={generalUtil.onKeyDown}
                    tableType={TableTypes.Open}
                    operation={Operations.Update}
                    axios={props.axios}
                    id={selectedIncidentId}
                    onLevelChange={handleLevelChange}
                    onTeamChange={handleTeamChange}
                    onSystemChange={handleSystemChange}
                    onDataCenterChange={handleDataCenterChange}
                    selectedLevel={selectedLevel}
                    selectedTeams={selectedTeams}
                    selectedSystems={selectedSystems}
                    selectedDataCenters={selectedDataCenters}
                />)
                : null}
        </React.Fragment>
    );
}

OpenTable.propTypes = propTypes;

export default OpenTable;
