const dateHelpers = {
    // Reformat an ISO date to HH:MM MM/DD/YYYY Timezone format
    reformatDate: function (isoDate) {
        let date = new Date(isoDate)
        if (date.toTimeString().match(/\((.+)\)/) !== null) {
            let tz = date.toTimeString().match(/\((.+)\)/)[1];
            return String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0') + " "
                + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + " " + tz;
        }
        return null;
    },

    getFormattedDuration: function (durationMinutes) {
        let days = Math.trunc(durationMinutes / (60 * 24));
        let hours = Math.trunc((durationMinutes % (60 * 24)) / 60);
        let minutes = (durationMinutes % 60)
        return days + " day(s) " + hours + " hour(s) " + minutes + " minute(s)";
    }
};

export default dateHelpers;