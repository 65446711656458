import React, {useEffect, useState} from 'react';
import Operations from "../../enums/Operations"
import PropTypes from 'prop-types';
import Table from "./Table"
import TableTypes from "../../enums/TableTypes";
import restUtil from "../../utils/RestUtil";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import generalUtil from "../../utils/GeneralUtil";
import Modal from "../ModalForm/Modal";

const propTypes = {
    axios: PropTypes.func.isRequired
};

const columns = [
    {
        dataField: 'team_id',
        text: 'Team Id',
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'distribution_email',
        text: 'Distribution Email'
    },
    {
        dataField: 'default_team',
        text: 'Default Team'
    }, {
        dataField: 'edit_and_delete',
        text: 'Edit and Delete'
    }
];

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

// This component contains the information to build a table from json data
const TeamsTable = props => {
    const [entries, setEntries] = useState([]);
    const [updateTeamModalIsShown, setUpdateTeamModalIsShown] = useState(false);
    const [deleteTeamModalIsShown, setDeleteTeamModalIsShown] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(null);

    useEffect(() => {
        props.axios.get('/sevbot/teams/allTeams')
        .then((result) => {
            setEntries(result.data);
        }).catch(function (error) {
            console.log(error);
        })
    }, [props.axios]);

    // Cleans the data returned from the database for usage in the table
    const getCleanData = () => {
        let cleanedEntries = structuredClone(entries);

        if (cleanedEntries !== null && cleanedEntries !== undefined) {
            for (let i = 0; i < cleanedEntries.length; i++) {
                let tableEntry = cleanedEntries.at(i);
                tableEntry['entry_id'] = tableEntry['team_id'];
                tableEntry['edit_and_delete'] =
                    <td key={`edit_and_delete${tableEntry['entry_id']}`}>
                        <FontAwesomeIcon icon={faEdit} className={"icon-button"}onClick={() => showUpdateTeamModal(tableEntry['entry_id'])}/>
                        <FontAwesomeIcon icon={faTrash} className={"icon-button"}onClick={() => showDeleteTeamModal(tableEntry['entry_id'])}/>
                    </td>
            }
        }
        return cleanedEntries;
    }

    // Handles submission of form for updating team
    const updateTeamOnSubmit = (event) => {
        event.preventDefault(event);
        let data = {
            id: event.target.id.value,
            name: event.target.name.value,
            email: event.target.email.value,
            defaultTeam: event.target.defaultTeam.checked
        }
        restUtil.putRequest(props.axios, '/sevbot/teams/updateTeam', data);
    }

    // Handles submission of team deletion form
    const deleteTeamOnSubmit = (event) => {
        let data = {id: event.target.id.value};
        restUtil.deleteRequest(props.axios, '/sevbot/teams/deleteTeam', data);
    }

    const showUpdateTeamModal = (tableRowId) => {
        setUpdateTeamModalIsShown(true);
        setSelectedTeamId(tableRowId);
        generalUtil.toggleScrollLock();
    };

    const closeUpdateTeamModal = () => {
        setUpdateTeamModalIsShown(false);
        generalUtil.toggleScrollLock();
    };

    const showDeleteTeamModal = (tableRowId) => {
        setDeleteTeamModalIsShown(true);
        setSelectedTeamId(tableRowId);
        generalUtil.toggleScrollLock();
    };

    const closeDeleteTeamModal = () => {
        setDeleteTeamModalIsShown(false);
        generalUtil.toggleScrollLock();
    };

    return (
        <React.Fragment>
            {/* The Teams Table */}
            <Table
                data={getCleanData()}
                columns={columns}
                defaultSorted={defaultSorted}
                keyField={'team_id'}
            />
            {/* The Update Modal for updating a team */}
            {updateTeamModalIsShown ? (<Modal
                onSubmit={updateTeamOnSubmit}
                closeModal={closeUpdateTeamModal}
                onKeyDown={generalUtil.onKeyDown}
                tableType={TableTypes.Teams}
                operation={Operations.Update}
                axios={props.axios}
                id={selectedTeamId}
            />) : null}
            {/* The Delete Modal for deleting a team */}
            {deleteTeamModalIsShown ? (<Modal
                onSubmit={deleteTeamOnSubmit}
                closeModal={closeDeleteTeamModal}
                onKeyDown={generalUtil.onKeyDown}
                tableType={TableTypes.Teams}
                operation={Operations.Delete}
                axios={props.axios}
                id={selectedTeamId}
            />) : null}
        </React.Fragment>
    );
}

TeamsTable.propTypes = propTypes;
export default TeamsTable;
