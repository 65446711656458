import React, {Component} from 'react';
import Select from 'react-select';
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Operations from "../../enums/Operations";
import moment from 'moment';

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    id: PropTypes.number,
    operation: PropTypes.symbol,
    axios: PropTypes.func.isRequired,
    onLevelChange: PropTypes.func.isRequired,
    onTeamChange: PropTypes.func.isRequired,
    onSystemChange: PropTypes.func.isRequired,
    onDataCenterChange: PropTypes.func.isRequired,
    selectedLevel: PropTypes.string,
    selectedTeams: PropTypes.array,
    selectedSystems: PropTypes.array,
    selectedDataCenters: PropTypes.array
}

const defaultProps = {
    id: -1,
    operation: null,
    axios: null,
    selectedLevel: null,
    selectedTeams: null,
    selectedSystems: null,
    selectedDataCenters: null
};

// Form for Contact Group Creation and Updating
export class IncidentCreateUpdateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                'title': '',
                'level': '',
                'start_time': '',
                'customer_impact_end_time': '',
                'slack_channel': '',
                'owner': '',
                'teams': '',
                'systems': '',
                'data_centers': '',
                'adhoc_emails': '',
                'creator_jira_username': '',
                'owner_jira_username': '',
            },
            teams: [],
            systems: [],
            dataCenters: [],
            systemInputIsValid: false,
            formattedDate: null
        };
        IncidentCreateUpdateForm.propTypes = propTypes;
        IncidentCreateUpdateForm.defaultProps = defaultProps;
    }

    componentDidMount() {

        if (this.props.operation === Operations.Update) {
            (this.props.axios).get(
                '/sevbot/incidents/getIncident',
                {
                    params: {
                        id: this.props.id
                    }
                }
            )
                .then((result) => {
                    // Incident update order seems to vary. Sort so latest update in front.
                    const sortedUpdates = result.data['updates'].sort((a, b) => {
                        return b.update_id - a.update_id;
                    });

                    this.setState({
                            data: result.data,
                            technical_update: sortedUpdates[0]['technical_update'],
                            customer_update: sortedUpdates[0]['customer_update'],
                            status_update: sortedUpdates[0]['status_update']
                        }, () => {

                        let previousTeams = [];

                        (result.data["teams"]).forEach((team) => {
                            previousTeams.push({value: team["team_id"], label: team['name']})
                        });

                        let previousSystems = [];
                        (result.data["incident_systems"]).forEach((system) => {
                            previousSystems.push({value: system["system_id"], label: system['name']})
                        });

                        let previousDataCenters = [];

                        (result.data["data_centers"]).forEach((center) => {
                            previousDataCenters.push({value: center["data_center_id"], label: center['name']})
                        });

                        this.props.onLevelChange(this.state.data['level']);
                        this.props.onTeamChange(previousTeams);
                        this.setState({
                            systemInputIsValid: true
                        });
                        this.props.onSystemChange(previousSystems);
                        this.props.onDataCenterChange(previousDataCenters);
                    });

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        let teams =[];
        let defaultTeams = [];
        (this.props.axios).get(
            '/sevbot/teams/allTeams',
        )
            .then((result) => {
                (result.data).forEach((entry) => {
                    teams.push({value: entry['team_id'], label: entry['name']})
                    if (entry['default_team'] && Object(this.props.operation).description === 'create') {
                        defaultTeams.push({value: entry['team_id'], label: entry['name']})
                    }
                });

                this.setState({
                        teams: teams
                    }, () => {
                        if (this.props.operation === Operations.Create) {
                            this.props.onTeamChange(defaultTeams);
                        }
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });

        (this.props.axios).get(
            '/sevbot/systems/getSystems'
        )
            .then((result) => {
                let systems = this.state.systems;
                (result.data).forEach((entry) => {
                    systems.push({value: entry["system_id"], label: entry['name']})
                });

                // Create new arrays so state change is detected
                let newSystems = systems.concat([]);

                this.setState({
                        systems: newSystems
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });

        (this.props.axios).get(
            '/sevbot/incidents/dataCenters'
        )
            .then((result) => {
                let dataCenters = this.state.dataCenters;
                (result.data).forEach((entry) => {
                    dataCenters.push({value: entry["data_center_id"], label: entry['name']})
                });

                // Create new arrays so state change is detected
                let newDataCenters = dataCenters.concat([]);

                this.setState({
                        dataCenters: newDataCenters
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
            });

        if (this.props.operation === Operations.Create) {
            this.props.onLevelChange("sev0");
        }

    }

    // Toggles if tooltip is visible
    onPopupClick(elementId) {
        let popup = document.getElementById(elementId);
        popup.classList.toggle("show");
    }

    handleLevelChange = (selectedLevel) => {
        this.props.onLevelChange(selectedLevel);
    }

    // Handles a change in the team form field
    handleTeamChange = (selectedTeams) => {
        this.props.onTeamChange(selectedTeams);
    }

    // Handles a change in the systems form field
    handleSystemChange = (selectedSystems) => {
        this.props.onSystemChange(selectedSystems);
        this.setState({
            systemInputIsValid: (selectedSystems.length !== 0)
        });
    }

    // Handles a change in the data center form field 
    handleDataCenterChange = (selectedDataCenters) => {
        this.props.onDataCenterChange(selectedDataCenters);
    }

    formatDate(date) {
        if (date !== null) {
            if (date.toTimeString().match(/\((.+)\)/) !== null) {
                return new Date(date.toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0];
            }
        }
        return null;
    }

    store_slack_ids() {
        // Creator and updater are mapped to the same element in the DOM. During uplift, may want to refactor HTML to make it so that these are separated
        let stored_creator_updater_id = localStorage.getItem("incident_creator_updater_id");
        let current_creator_updater_id = document.getElementById("creator").value;
        let stored_owner_id = localStorage.getItem("incident_owner_id");
        let current_owner_id = document.getElementById("owner").value;

        if (!stored_creator_updater_id || stored_creator_updater_id !== current_creator_updater_id) {
            localStorage.setItem("incident_creator_updater_id", current_creator_updater_id);
        }

        if (!stored_owner_id || stored_owner_id !== current_owner_id) {
            localStorage.setItem("incident_owner_id", current_owner_id);
        }
    }

    render() {
        let formattedStartDate;
        if (this.state.data['start_time'] !== null) {
            formattedStartDate = this.formatDate(new Date(this.state.data['start_time']));
        }
        let formattedEndDate;
        if (this.state.data['customer_impact_end_time'] !== null) {
            formattedEndDate = this.formatDate(new Date(this.state.data['customer_impact_end_time']));
        }
        let currentTime = new Date(moment().format());

        return (
            <form className="popup-form" onSubmit={event => {this.store_slack_ids(); this.props.onSubmit(event, this.props.id, this.props.selectedLevel)}}>
                {(this.props.operation === Operations.Update) &&
                    <div className="form-group">
                        <input
                            type="checkbox"
                            id="email_changes"
                            defaultChecked={true}
                        />
                        <label htmlFor="email_changes">
                            Email These Changes? (Optional)
                        </label>
                    </div>
                }
                {(this.props.operation === Operations.Update) &&
                    <div className="form-group">
                        <input
                            type="checkbox"
                            id="nagging"
                            defaultChecked={Object(this.props.operation).description === 'update' ? this.state.data['nag_enabled']
                                : true}
                        />
                        <label htmlFor="nagging">
                            Nagging? (Optional)
                        </label>
                    </div>
                }
                    <div className="form-group">
                        <label htmlFor="title">Incident Title</label>
                        <input
                            className="form-control"
                            id="title"
                            defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['name']
                                : null}
                            placeholder="SEV description"
                            required="required"
                        />
                    </div>
                <div className="form-group">
                    <label htmlFor="level">Severity</label>
                    <select
                        className="form-control"
                        id="level"
                        value={(this.props.selectedLevel)}
                        onChange={(e) => this.handleLevelChange(e.target.value)}
                    >
                        <option value="sev0">SEV0</option>
                        <option value="sev1">SEV1</option>
                        <option value="sev2">SEV2</option>
                        <option value="sev3">SEV3</option>
                        <option value="info">INFO</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="start_time">Start Time</label>
                    <input
                        className="form-control"
                        id="start_time"
                        type="datetime-local"
                        defaultValue={Object(this.props.operation).description === 'update' ? formattedStartDate
                            : null}
                        max={currentTime}
                        required="required"
                    />
                </div>
                {(this.props.operation === Operations.Update) &&
                    <div className="form-group">
                        <label htmlFor="customer_impact_end_time">Customer Impact End Time (Optional)</label>
                        <input
                            className="form-control"
                            id="customer_impact_end_time"
                            type="datetime-local"
                            defaultValue={Object(this.props.operation).description === 'update' ? formattedEndDate
                                : null}
                            max={currentTime}
                        />
                    </div>
                }
                <div className="form-group">
                    <div>
                        <label htmlFor="slack_channel">Slack Channel ID (Optional)</label>
                        <div className="popup" onClick={() => this.onPopupClick('channel_popup')}><FontAwesomeIcon
                            icon={faCircleInfo} data-tip data-for='channelTip' className="icon-button"/>
                            <span className="popuptext" id="channel_popup">
                                <div className="popup-instructions">
                                    To find the Slack channel id, open the channel in Slack.
                                    Click the name of the channel at the top of the window.
                                    Click the copy button at the bottom of the popup window.
                                    Paste the result here.
                                </div>
                            </span>
                        </div>
                    </div>
                    <input
                        className="form-control"
                        id="slack_channel"
                        type="text"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['optional_channel_id']
                            : null}
                        placeholder="Please enter the channel id"
                    />
                </div>
                <div className="form-group">
                    <div>
                        <label
                            htmlFor="creator">{Object(this.props.operation).description === 'update' ? "Incident Updater ID" : "Incident Creator ID"}</label>
                        <div className="popup" onClick={() => this.onPopupClick('creator_popup')}><FontAwesomeIcon
                            icon={faCircleInfo} data-tip data-for='creatorTip' className="icon-button"/>
                            <span className="popuptext" id="creator_popup">
                                <div className="popup-instructions">
                                    Please enter your slack id here.
                                    To find a user's id, click on their profile within Slack.
                                    Click the ellipsis to the right of the "Huddle" button.
                                    Click "Copy member ID" and paste it here.
                                </div>
                            </span>
                        </div>
                    </div>
                    <input
                        className="form-control"
                        id="creator"
                        type="text"
                        placeholder={Object(this.props.operation).description === 'update' ? "Please enter the updater's slack id"
                            : "Please enter the creator's slack id"}
                        defaultValue={!localStorage.getItem("incident_creator_updater_id") ? "" : localStorage.getItem("incident_creator_updater_id")}
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <div>
                        <label
                            htmlFor="creator_jira_username">{Object(this.props.operation).description === 'update' ? "Incident Updater Jira Username (Optional)" : "Incident Creator Jira Username (Optional)"}</label>
                    </div>
                    <input
                        className="form-control"
                        id="creator_jira_username"
                        type="text"
                        placeholder={Object(this.props.operation).description === 'update' ? "Please enter the updater's jira username"
                            : "Please enter the creator's jira username"}
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['creator_jira_username']
                            : null}
                    />
                </div>
                <div className="form-group">
                    <div>
                        <label htmlFor="owner">Incident Owner ID</label>
                        <div className="popup" onClick={() => this.onPopupClick('owner_popup')}><FontAwesomeIcon
                            icon={faCircleInfo} data-tip data-for='ownerTip' className="icon-button"/>
                            <span className="popuptext" id="owner_popup">
                                <div className="popup-instructions">
                                    To find the user's id click on their profile within Slack.
                                    Click the ellipsis to the right of the "Huddle" button.
                                    Click "Copy member ID" and paste it here.
                                </div>
                            </span>
                        </div>
                    </div>
                    <input
                        className="form-control"
                        id="owner"
                        type="text"
                        placeholder="Please enter the owner's slack id"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['owner']
                            : (!localStorage.getItem("incident_owner_id") ? "" : localStorage.getItem("incident_owner_id"))}
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <div>
                        <label htmlFor="owner_jira_username">Incident Owner Jira Username (Optional)</label>
                    </div>
                    <input
                        className="form-control"
                        id="owner_jira_username"
                        type="text"
                        placeholder="Please enter the owner's jira username"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['owner_jira_username']
                            : null}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="teams">Incident Communication Teams (Optional)</label>
                    <Select
                        isMulti
                        name="teams"
                        id="teams"
                        value={this.props.selectedTeams}
                        onChange={(e) => this.handleTeamChange(e)}
                        options={this.state.teams}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="systems">Incident Systems</label>
                    <Select
                        isMulti
                        name="systems"
                        id="systems"
                        value={this.props.selectedSystems}
                        onChange={(e) => this.handleSystemChange(e)}
                        options={this.state.systems}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        required="required"
                    />
                    {!this.state.systemInputIsValid &&
                        <p className="invalid-warning-form">You must choose at least one value</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="data_centers">Affected Data Centers (Optional)</label>
                    <Select
                        isMulti
                        name="data_centers"
                        id="data_centers"
                        value={this.props.selectedDataCenters}
                        onChange={(e) => this.handleDataCenterChange(e)}
                        options={this.state.dataCenters}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="technical_impact">Current Technical Impact</label>
                    <textarea
                        className="form-control"
                        id="technical_impact"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.technical_update
                            : null}
                        rows="4"
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="customer_impact">Current Customer Impact</label>
                    <textarea
                        className="form-control"
                        id="customer_impact"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.customer_update
                            : null}
                        rows="4"
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="status_update">Status Update</label>
                    <textarea
                        className="form-control"
                        id="status_update"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.status_update
                            : null}
                        rows="4"
                        required="required"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="communication_emails">Ad Hoc Communication Emails (Optional)</label>
                    <textarea
                        className="form-control"
                        id="communication_emails"
                        defaultValue={Object(this.props.operation).description === 'update' ? this.state.data['adhoc_emails']
                            : null}
                        rows="2"
                        placeholder="Delimit emails by ,"
                    />
                </div>
                <div className="form-group">
                    <button disabled={!this.state.systemInputIsValid}
                            className="form-control btn btn-primary modal-submission" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        );
    }
}

export default IncidentCreateUpdateForm;
