import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.number,
  startTime: PropTypes.string,
  submitClick: PropTypes.func
};

const CloseModal = (props) => {
  let currentDateTime = moment().format();
  // Remove the seconds and milliseconds from the date 
  currentDateTime = currentDateTime.substring(0, currentDateTime.length - 9);
  const [dateTime, setDateTime] = useState(currentDateTime);
  const [closerId, setCloserId] = useState('');
  const [closedJira, setClosedJira] = useState(true);


  const handleSubmit = (event) => {
    event.preventDefault();
    props.submitClick(dateTime, closerId, closedJira)
  };

  const handleCheckboxChange = () => {
    setClosedJira(!closedJira);
  };

  useEffect(() => {
    setClosedJira(true);
  }, [props.show]);


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Close Incident
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Enter Custom Close Date</Form.Label>
            <Form.Control type="datetime-local"
                          placeholder="Enter email"
                          defaultValue={currentDateTime}
                          onChange={dt => setDateTime(dt.target['value'])}
                          required
            />
            <Form.Label>Incident Closer</Form.Label>
            <Form.Control className="form-control"
                          id="updater"
                          type="text"
                          title="Please enter the updater's slack id"
                          placeholder="Please enter the updater's slack id"
                          onChange={dt => setCloserId(dt.target['value'])}
                          required
            />
            <br></br>
            <Form.Check
                type="checkbox"
                label="Close SEV Jira"
                onChange={handleCheckboxChange}
                defaultChecked={true}
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CloseModal.propTypes = propTypes;

export default CloseModal;