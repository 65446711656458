const SortBehavior = {
    SortByIdAsc: Symbol("sortByIdAsc"),
    SortByIdDesc: Symbol("sortByIdDesc"), //default
    SortByNameAsc: Symbol("sortByNameAsc"),
    SortByNameDesc: Symbol("sortByNameDesc"),

    toString: function(sortBehavior) {
        switch (sortBehavior) {
            case this.SortByIdAsc:
                return "SORT_BY_ID_ASC";
            case this.SortByIdDesc:
                return "SORT_BY_ID_DESC";
            case this.SortByNameAsc:
                return "SORT_BY_NAME_ASC";
            case this.SortByNameDesc:
                return "SORT_BY_NAME_DESC";
            default:
                return "";
        }
    }
};

export default SortBehavior;
