import React, { Component } from 'react';
import PropTypes from "prop-types";
import Operations from "../../enums/Operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import restUtil from "../../utils/RestUtil";
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    operation: PropTypes.string,
    id: PropTypes.number,
    axios: PropTypes.func.isRequired
};

const defaultProps = {
    operation: null,
    id: -1,
    axios: null
};

class SystemCreateUpdateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                'name': '',
                'escalation_policy_id': ''
            }
        };
    }

    componentDidMount() {
        if (this.props.operation === Operations.Update) {
            let data = {
                id: this.props.id
            };

            restUtil.getRequestWithData(this.props.axios,
                '/sevbot/systems/getSystemById', data)
                .then((responseData) => {
                    this.setState({
                        data: responseData[0]
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    onPopupClick(elementId) {
        let popup = document.getElementById(elementId);
        popup.classList.toggle("show");
    }

    render() {
        const tooltip = (
            <Tooltip id="tooltip">
                To find an escalation policy Id, navigate to Pager Duty, and click on Escalation Policies
                under the People Tab in the navigation bar. Then click on a policy and in the URL, the Id will be
                displayed at the end after the '#' sign. For example if the URL is https://garmin.pagerduty.com/escalation_policies#PUCTG21
                then the policy Id is: PUCTG21
            </Tooltip>
        );

        return (
            <Form onSubmit={this.props.onSubmit}>
                <input type="hidden" id="id" value={this.props.id} />
                <div className="form-group">
                    <FormLabel htmlFor="name">System Name</FormLabel>
                    <FormControl
                        className="form-control"
                        id="name"
                        defaultValue={this.props.operation === Operations.Update ? this.state.data['name'] : null}
                        placeholder="Please Enter System Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <FormLabel htmlFor="escalationPolicy">Escalation Policy Id Number</FormLabel>
                    <OverlayTrigger placement="left" overlay={tooltip}>
                        <FontAwesomeIcon icon={faCircleInfo} data-tip data-for='creatorTip' className="icon-button" />
                    </OverlayTrigger>
                    <InputGroup>
                        <FormControl
                            className="form-control"
                            id="escalationPolicy"
                            defaultValue={this.props.operation === Operations.Update ? this.state.data['escalation_policy_id'] : null}
                            placeholder="Please Enter Escalation Policy Id Number"
                        />
                    </InputGroup>
                </div>
                <div className="form-group">
                    <Button className="form-control btn btn-primary" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        );
    }
}

SystemCreateUpdateForm.propTypes = propTypes;
SystemCreateUpdateForm.defaultProps = defaultProps;

export default SystemCreateUpdateForm;
