import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const propTypes = {
    onSearchTermChange: PropTypes.func.isRequired,
    delay: PropTypes.number
};

const DEFAULT_DEBOUNCE_TIME_MS = 500;

/**
 * Custom search bar needed for remote filtering because SearchBar from react-bootstrap-table2-toolkit does not seem
 * to trigger a filter event for onTableChange when remote={ {filter: true} }. This component manually trigger it on
 * text change after a debounce period to ensure we don't flood the backend with requests.
 **/
const RemoteSearchBar = ({ onSearchTermChange, delay = DEFAULT_DEBOUNCE_TIME_MS }) => {
    const [inputValue, setInputValue] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchDebounced = useCallback(
        debounce((value) => {
            onSearchTermChange(value);
        }, delay),
        []
    );

    const handleChange = event => {
        let value = event.target.value
        setInputValue(value);
        handleSearchDebounced(value);
    };

    return (
        <div className="input-group">
            <input
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={inputValue}
                onChange={handleChange}
            />
        </div>
    );
};

RemoteSearchBar.propTypes = propTypes;

export default RemoteSearchBar;
