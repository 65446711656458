import React from 'react';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import RemoteSearchBar from './RemoteSearchBar';

//Type-Check input props
const propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    totalRows: PropTypes.number,
    onTableChange: PropTypes.func,
    defaultSorted: PropTypes.array,
    keyField: PropTypes.string,
    remoteFiltering: PropTypes.bool,
    remotePagination: PropTypes.bool,
    remoteSorting: PropTypes.bool
};

const {SearchBar} = Search;

const Table = ({ data, columns, page, sizePerPage, totalSize, onTableChange, defaultSorted, keyField,
                   remoteFiltering, remotePagination, remoteSorting }) => {

    const triggerFilterEvent = (searchTerm) => {
        const filters = {};
        filters['search'] = searchTerm;
        onTableChange('filter', { filters });
    };

    return (
        <div>
            <ToolkitProvider
                bootstrap4
                keyField={keyField}
                data={data}
                columns={columns}
                search={{searchFormatted: true}}
            >
                {(toolProps) => (
                    <div>
                        <div className='searchBar'>
                            {remoteFiltering ?
                                <RemoteSearchBar onSearchTermChange={triggerFilterEvent}/> :
                                <SearchBar {...toolProps.searchProps} srText=''/>
                            }
                        </div>
                        <div className='incidentTable'>
                            <BootstrapTable
                                remote={{
                                    filter: remoteFiltering,
                                    pagination: remotePagination,
                                    sort: remoteSorting,
                                    cellEdit: false
                                }}
                                {...toolProps.baseProps}
                                pagination={paginationFactory({page, sizePerPage, totalSize})}
                                onTableChange={onTableChange}
                                defaultSorted={defaultSorted}
                                striped
                                hover
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
        </div>
    );
};

Table.propTypes = propTypes;

export default Table;
