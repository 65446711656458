import React, {useEffect, useState} from 'react';
import Operations from "../../enums/Operations"
import PropTypes from 'prop-types';
import Table from "./Table"
import TableTypes from "../../enums/TableTypes";
import restUtil from "../../utils/RestUtil";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import generalUtil from "../../utils/GeneralUtil";
import Modal from "../ModalForm/Modal";

const propTypes = {
    axios: PropTypes.func.isRequired
};

const columns = [
    {
        dataField: 'system_id',
        text: 'System Id',
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'hyperLink_escalation_policy_id',
        text: 'Escalation  Policy Id'
    },
   {
        dataField: 'edit_and_delete',
        text: 'Edit and Delete'
    }
];

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

// This component contains the information to build a table from json data
const SystemsTable = props => {
    const [entries, setEntries] = useState([]);
    const [updateSystemModalIsShown, setUpdateSystemModalIsShown] = useState(false);
    const [deleteSystemModalIsShown, setDeleteSystemModalIsShown] = useState(false);
    const [selectedSystemId, setSelectedSystemId] = useState(null);

    const pagerDutyUrl = "https://garmin.pagerduty.com/escalation_policies#";

    useEffect(() => {
        restUtil.getRequest(props.axios, "/sevbot/systems/getSystems", setEntries);
    }, [props.axios]);

    // Cleans the data returned from the database for usage in the table
    const getCleanData = () => {
        let cleanedEntries = structuredClone(entries);

        if (cleanedEntries !== null && cleanedEntries !== undefined) {
            for (let i = 0; i < cleanedEntries.length; i++) {
                let tableEntry = cleanedEntries.at(i);
                tableEntry['entry_id'] = tableEntry['system_id'];
                tableEntry['edit_and_delete'] =
                    <td key={`edit_and_delete${tableEntry['entry_id']}`}>
                        <FontAwesomeIcon icon={faEdit} className={"icon-button"}onClick={() => showUpdateSystemModal(tableEntry['entry_id'])}/>
                        <FontAwesomeIcon icon={faTrash} className={"icon-button"}onClick={() => showDeleteSystemModal(tableEntry['entry_id'])}/>
                    </td>
                tableEntry['hyperLink_escalation_policy_id'] = (
                    <a href={pagerDutyUrl+tableEntry['escalation_policy_id']} target="_blank" rel="noopener noreferrer">
                        {tableEntry['escalation_policy_id']}
                    </a>
                );
            }
        }
        return cleanedEntries;
    }

    // Handles submission of form for updating systems
    const updateSystemOnSubmit = (event) => {
        event.preventDefault(event);
        let data = {
            id: event.target.id.value,
            name: event.target.name.value,
            system_names: event.target.escalationPolicy.value
        }
        restUtil.putRequest(props.axios, '/sevbot/systems/updateSystem', data);
    }

    // Handles submission of system deletion form
    const deleteSystemOnSubmit = (event) => {
        let data = {id: event.target.id.value};
        restUtil.deleteRequest(props.axios, '/sevbot/systems/deleteSystem', data);
    }

    const showUpdateSystemModal = (tableRowId) => {
        setUpdateSystemModalIsShown(true);
        setSelectedSystemId(tableRowId);
        generalUtil.toggleScrollLock();
    };

    const closeUpdateSystemModal = () => {
        setUpdateSystemModalIsShown(false);
        generalUtil.toggleScrollLock();
    };

    const showDeleteSystemModal = (tableRowId) => {
        setDeleteSystemModalIsShown(true);
        setSelectedSystemId(tableRowId);
        generalUtil.toggleScrollLock();
    };

    const closeDeleteSystemModal = () => {
        setDeleteSystemModalIsShown(false);
        generalUtil.toggleScrollLock();
    };

    return (
        <React.Fragment>
            {/* The Systems Table */}
            <Table
                data={getCleanData()}
                columns={columns}
                defaultSorted={defaultSorted}
                keyField={'system_id'}
            />
            {/* The Update Modal for updating a system */}
            {updateSystemModalIsShown ? (<Modal
                onSubmit={updateSystemOnSubmit}
                closeModal={closeUpdateSystemModal}
                onKeyDown={generalUtil.onKeyDown}
                tableType={TableTypes.Systems}
                operation={Operations.Update}
                axios={props.axios}
                id={selectedSystemId}
            />) : null}
            {/* The Delete Modal for deleting a system */}
            {deleteSystemModalIsShown ? (<Modal
                onSubmit={deleteSystemOnSubmit}
                closeModal={closeDeleteSystemModal}
                onKeyDown={generalUtil.onKeyDown}
                tableType={TableTypes.Systems}
                operation={Operations.Delete}
                axios={props.axios}
                id={selectedSystemId}
            />) : null}
        </React.Fragment>
    );
}

SystemsTable.propTypes = propTypes;
export default SystemsTable;
