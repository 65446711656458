import React from 'react';
import PropTypes from "prop-types";

// General button for modal/form rendering and CRUD functions
const TriggerButton = ({ triggerText, buttonRef, showModal }) => {
    return (
        <button
            class="create-button"
            className="btn btn-primary left modal-button create-btn"
            ref={buttonRef}
            onClick={showModal}>
            {triggerText}
        </button>
    );
};

TriggerButton.propTypes = {
    triggerText: PropTypes.string,
    buttonRef: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
}
export default TriggerButton;
